<template>
  <div class="relative mx-auto licenses-step">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-file-certificate"></i>
      8th step: Licenses
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select which licenses and certificates you have)
    </span>

    <!-- Switches/Checkboxes component -->
    <SwitchesCheckboxes
      :options="licensesOptions"
      :model-value="localLicensesData"
      @update:modelValue="updateLicensesData"
    />
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "LicensesStep",
  components: { SwitchesCheckboxes },
  props: {
    licensesData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localLicensesData: { ...this.licensesData },
      licensesOptions: [
        { name: "iso9001", label: "ISO 9001", value: false },
        { name: "iso14001", label: "ISO 14001", value: false },
        { name: "iso45001", label: "ISO 45001", value: false },
        { name: "ohsas18001", label: "OSHAS 18001", value: false },
        { name: "haccp", label: "HACCP", value: false },
        { name: "customs", label: "Customs Certificate", value: false },
        { name: "chemicalHandling", label: "Chemicals Handling", value: false },
        { name: "fireSafety", label: "Fire Safety", value: false },
        { name: "breeam", label: "BREEAM Certificate", value: false },
        { name: "leed", label: "LEED Certificate", value: false },
      ],
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    licensesData: {
      deep: true,
      handler(newVal) {
        this.localLicensesData = { ...newVal };
      },
    },
  },
  methods: {
    updateLicensesData(updatedData) {
      this.localLicensesData = updatedData;
      // Emit the update to parent component
      this.$emit("update:licensesData", updatedData);
    },
  },
};
</script>
